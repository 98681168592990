import React from "react"
import FormBase from "./FormBase"
import axios from "axios";
import $ from "jquery"
import PropTypes from "prop-types";
import { toast } from "react-toastify"

class PhoneScreenRequest extends FormBase {
    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            form: {},
            spinner: false
        }

        this.required_fields = []
        this.handleSubmitToServer = this.handleSubmitToServer.bind(this)
    }

    handleSubmitToServer(form) {
        this.setState({spinner: true}, function () {
            axios.post('/api/public/schedule_request/' + this.props.slug, form)
                .then((response) => {
                    this.setState({spinner: false, form: {}}, function () {
                        toast('Request sent!', {
                            type: toast.TYPE.SUCCESS,
                            closeButton: true,
                            autoClose: 5000,
                            hideProgressBar: true,
                            draggable: false,
                            position: toast.POSITION.TOP_RIGHT
                        })

                        $("[data-dismiss=modal]").trigger({type: "click"})
                        _dcq.push(["track", "Phone Screen Request", {}]) // track in getdrip.com
                    })
                })
                .catch((error) => {
                    this.setState({spinner: false}, function () {
                        this.handleErrors(error)
                    })
                })
        })
    }

    render() {
        const form = this.state.form

        return (
            <React.Fragment>
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <form method="post" onSubmit={this.handleSubmit}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="request_phone_screen_title">Request Phone Screen</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.errors.length > 0 && this.renderErrors()}

                                <div className="form-group">
                                    <label htmlFor="text-name">Name</label>
                                    <input className="form-control" id="text-name" name="name" value={form.name || ''} onChange={this.handleChange} required={true}/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="text-employer">Title</label>
                                    <input className="form-control" id="text-title" name="title" value={form.title || ''} onChange={this.handleChange} required={true}/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="text-title">Employer</label>
                                    <input className="form-control" id="text-employer" name="employer" value={form.employer || ''} onChange={this.handleChange} required={true}/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="text-email">Email</label>
                                    <input className="form-control" id="text-email" name="email" type="email" value={form.email || ''} onChange={this.handleChange} required={true}/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="text-phone">Phone</label>
                                    <input className="form-control" id="text-phone" name="phone" value={form.phone || ''} onChange={this.handleChange} required={true}/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="textarea-notes">Preferred Day and Times</label>
                                    <textarea className="form-control" id="textarea-notes" value={form.notes || ''} name="notes" rows="10" onChange={this.handleChange} required={true}/>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {this.state.spinner && (
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Submitting...</span>
                                    </div>
                                )}
                                <input type="submit" className="btn btn-primary" value="Submit"/>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

PhoneScreenRequest.propTypes = {
    slug: PropTypes.string.isRequired
}
export default PhoneScreenRequest
