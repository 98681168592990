import React from "react"
import PropTypes from "prop-types"
import FormBase from "./FormBase"
import axios from "axios"

class PasswordUpdate extends FormBase {
  constructor(props) {
    super(props)

    this.required_fields = ['password', 'password_confirmation']
    this.handleSubmitToServer = this.handleSubmitToServer.bind(this)
  }

  handleSubmitToServer(form) {
    axios.put('/api/password_resets/' + this.props.token, {user: form})
      .then((response) => {
        window.location.href = '/candidate/dashboard'
      })
      .catch((error) => {
        this.handleErrors(error)
      })
  }

  render() {
    const form = this.state.form

    return (
      <React.Fragment>
        <div id="layoutAuthentication">
          <div id="layoutAuthentication_content">
            <main>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-5">
                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                      <div className="card-header"><h3 className="text-center font-weight-light my-4">Update
                        Password</h3></div>
                      <div className="card-body">
                        {this.state.errors.length > 0 && this.renderErrors()}
                        <form method="post" onSubmit={this.handleSubmit}>
                          <div className="form-group">
                            <label className="small mb-1" htmlFor="password">Password</label>
                            <input className="form-control py-4" type="password" name="password"
                                   placeholder="Enter password"
                                   value={form.password || ''} onChange={this.handleChange}/>
                          </div>
                          <div className="form-group">
                            <label className="small mb-1" htmlFor="password_confirmation">Password (confirm)</label>
                            <input className="form-control py-4" type="password" name="password_confirmation"
                                   placeholder="Confirm password"
                                   value={form.password_confirmation || ''} onChange={this.handleChange}/>
                          </div>
                          <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                            <input type="submit" className="btn btn-primary" value="Update Password"/>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

PasswordUpdate.propTypes = {
  token: PropTypes.string.isRequired
}
export default PasswordUpdate
