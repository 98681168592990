// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// setup defaults for axios (allow form posts)
import axios from 'axios'

const csrfToken = document.querySelector("meta[name=csrf-token]").content
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
axios.defaults.headers.common['Accept'] = 'application/json'

// hello sign
import HelloSign from 'hellosign-embedded'
// only import on pages that need it
if (gon.hellosign_client_id) {
    const client = new HelloSign({
        clientId: gon.hellosign_client_id
    })
    window.hellosign_client = client
}

let jobAppError = document.getElementById('new_job_app_error')
let jobAppForm = document.getElementById('new_job_app')
if (jobAppForm && jobAppError) {
    jobAppForm.addEventListener('ajax:success', () => {
        window.location.href = '/jobs'
    })
    jobAppForm.addEventListener('ajax:error', (event, response) => {
        jobAppError.classList.add('error')
        let responseJson = JSON.parse(event.detail[2].response)
        jobAppError.innerText = responseJson['errors'].join(', ')
    })
}

const resumeBtn = document.getElementById('job_app_resume')
const resumeLabel = document.getElementById('job_app_resume_label')
if (resumeBtn && resumeLabel) {
    resumeBtn.addEventListener('change', function () {
        resumeLabel.textContent = this.files[0].name
    })
}
