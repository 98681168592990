import React from "react"
import PropTypes from "prop-types"
import FormBase from "./FormBase"
import axios from "axios"

class UploadTimesheet extends FormBase {
  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      form: {
        assignment_id: this.props.assignment_id,
        week_of_date: this.props.fridays[0]
      },
      spinner: false
    }

    this.required_fields = []
    this.handleSubmitToServer = this.handleSubmitToServer.bind(this)
  }

  handleSubmitToServer(form) {
    const formData = new FormData()
    for (let key in form) {
      formData.append('timesheet[' + key + ']', form[key])
    }

    this.setState({spinner: true}, function () {
      axios.post('/api/timesheets', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          this.setState({spinner: false})
          window.location = this.props.success_url
        })
        .catch((error) => {
          this.setState({spinner: false}, function () {
            this.handleErrors(error)
          })
        })
    })
  }

  render() {
    const form = this.state.form

    return (
      <React.Fragment>
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <form method="post" onSubmit={this.handleSubmit}>
              <div className="modal-header">
                <h5 className="modal-title" id="timesheet_{this.props.assignment_id}_title">Upload Timesheet
                  for {this.props.title}</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span
                  aria-hidden="true">×</span></button>
              </div>
              <div className="modal-body">
                {this.state.errors.length > 0 && this.renderErrors()}

                <div className="form-group">
                  <label htmlFor="timesheet_week_of_date">Week Ending Date</label>
                  <select className="form-control" id="timesheet_week_of_date" name="week_of_date"
                          value={form.week_of_date} onChange={this.handleChange}>
                    {this.props.fridays.map((friday) =>
                        <option value={friday} key={friday}>{friday}</option>
                    )}
                  </select>
                </div>

                <div className="form-group form-check">
                  <input className="form-check-input" id="timesheet_no_hours" type="checkbox"
                         name="no_hours" checked={form.no_hours} value={true} onChange={this.handleChange}/>
                  <label className="form-check-label" htmlFor="timesheet_no_hours">I Worked No Hours During This Week</label>
                </div>

                <div className="form-group" className={form.no_hours ? 'd-none' : ''}>
                  <label htmlFor="timesheet_timesheet">Timesheet Image</label>
                  <input className="form-control" id="timesheet_timesheet" type="file" accept="image/jpeg,image/jpg,image/png,application/pdf"
                         name="timesheet" onChange={this.handleChange}/>
                </div>
              </div>
              <div className="modal-footer">
                {this.state.spinner && (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Uploading...</span>
                  </div>
                )}
                <input type="submit" className="btn btn-primary" value="Upload"/>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

UploadTimesheet.propTypes = {
  assignment_id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  success_url: PropTypes.string.isRequired,
  fridays: PropTypes.array.isRequired
}
export default UploadTimesheet
