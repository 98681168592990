import React from "react"

export class FormBase extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            form: {},
            errors: []
        }

        this.handleChange = this.handleChange.bind(this)
        this.updateForm = this.updateForm.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event, callback) {
        const target = event.target
        let value = target.value
        if (target.type === 'checkbox') value = target.checked
        else if (target.type === 'file' && !target.multiple) value = target.files[0]
        else if (target.type === 'file' && target.multiple) value = target.files
        else if (target.multiple) value = Array.from(target.selectedOptions, option => option.value)
        const name = target.name

        let form = {...this.state.form}
        form[name] = value

        this.setState({
            form: form
        }, callback)
    }

    updateForm(name, value) {
        this.setState(prevState => {
            let form = {...prevState.form}
            form[name] = value

            return {
                form: form
            }
        }) //, () => console.log(this.state.form))
    }

    handleSubmit(event) {
        event.preventDefault()

        let errors = this.validate()

        if (errors.length > 0) {
            this.setState({errors: errors})
        } else {
            this.setState({errors: []})
            this.handleSubmitToServer(this.state.form)
        }
    }

    validate() {
        let form = this.state.form
        let errors = []
        let required_fields = this.required_fields

        if (required_fields.filter(n => form[n]).length !== required_fields.length)
            errors.push("All fields required")

        return errors
    }

    handleErrors(error) {
        let errors

        if (error.response && error.response.data.errors) {
            errors = error.response.data.errors
        } else {
            errors = ['Unknown error']
        }

        this.setState({errors: errors})
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        )
    }

    renderErrors() {
        return (
            <div className="alert alert-danger text-left" role="alert">
                {this.state.errors.map((error, key) =>
                    <div key={key}>{error}</div>
                )}
            </div>
        )
    }
}

export default FormBase
