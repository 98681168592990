import React from "react"
import PropTypes from "prop-types"
import FormBase from "./FormBase"
import axios from "axios"

class UploadComplianceDocument extends FormBase {
    constructor(props) {
        super(props)

        this.state = {
            ...this.state,
            form: {
                file_label_id: this.props.file_label_id
            },
            spinner: false
        }

        this.required_fields = []
        this.handleSubmitToServer = this.handleSubmitToServer.bind(this)
    }

    handleSubmitToServer(form) {
        const documents = Array.prototype.slice.call(form.document)
        documents.forEach((document) => {
            const formData = new FormData()
            for (let key in form) {
                if (key === 'document') formData.append('user_file[' + key + ']', document)
                else formData.append('user_file[' + key + ']', form[key])
            }

            this.setState({spinner: true}, function () {
                axios.post('/api/user_files', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then((response) => {
                        this.setState({spinner: false})
                        window.location = this.props.success_url
                    })
                    .catch((error) => {
                        this.setState({spinner: false}, function () {
                            this.handleErrors(error)
                        })
                    })
            })
        })
    }

    render() {
        const form = this.state.form

        return (
            <React.Fragment>
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <form method="post" onSubmit={this.handleSubmit}>
                            <div className="modal-header">
                                <h5 className="modal-title"
                                    id="upload_compliance_{this.props.file_label_id}_title">Upload Compliance
                                    Document</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                {this.state.errors.length > 0 && this.renderErrors()}
                                <div className="form-group">
                                    <label htmlFor="user_file_document">{this.props.label}</label>
                                    <input className="form-control" id="user_file_document" type="file" multiple={true}
                                           name="document" onChange={this.handleChange}/>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {this.state.spinner && (
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Uploading...</span>
                                    </div>
                                )}
                                <input type="submit" className="btn btn-primary" value="Upload"/>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

UploadComplianceDocument.propTypes = {
    file_label_id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    success_url: PropTypes.string.isRequired
}
export default UploadComplianceDocument
