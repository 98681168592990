export function generateTimes(inc) {
    let times = ['']
        , periods = ['AM', 'PM']
        , hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
        , prop = null
        , hour = null
        , min = null

    for (prop in periods) {
        for (hour in hours) {
            for (min = 0; min < 60; min += inc) {
                times.push(`${padZero(hours[hour])}:${padZero(min)} ${periods[prop]}`)
            }
        }
    }

    for (prop in periods) {
        for (hour in hours) {
            for (min = 0; min < 60; min += inc) {
                times.push(`${padZero(hours[hour])}:${padZero(min)} ${periods[prop]} (next day)`)
            }
        }
    }

    return times
}

export function padZero(num) {
    return num.toString().padStart(2, '0');
}


export function parseTime(timeStr) {
    if (timeStr === undefined || timeStr == null || timeStr == '') return null

    let dayOffset = 0
    if (timeStr.endsWith(" (next day)")) dayOffset = 24 * 60

    let [hourMins, period] = timeStr.split(" ")
    let [hour, minute] = hourMins.split(":")
    if (hour == 12 && period == 'AM') hour = 0
    let val = parseInt(hour) * 60 + parseInt(minute)
    if (period == 'PM' && hour < 12) val += 12 * 60

    return val + dayOffset
}

export function isEmpty(o) {
    if (o === undefined) return true
    if (o === null) return true
    if (o === '') return true
    return false
}

export function parseLunch(durStr) {
    if (durStr === undefined || durStr == null || durStr == '') return null

    let [dur, _unit] = durStr.split(" ")  // currently assuming only 'min' for units
    let val = parseInt(dur) / 60
    return val
}
