import React from "react"
import FormBase from "./FormBase"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import $ from "jquery"

class HelpRequest extends FormBase {
  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      form: {},
      spinner: false
    }

    this.required_fields = [] //['message']
    this.handleSubmitToServer = this.handleSubmitToServer.bind(this)
  }

  handleSubmitToServer(form) {
    this.setState({spinner: true}, function () {
      axios.post('/api/contact', form)
        .then((response) => {
          this.setState({spinner: false, form: {}}, function () {
            toast('Message sent!', {
              type: toast.TYPE.SUCCESS,
              closeButton: true,
              autoClose: 5000,
              hideProgressBar: true,
              draggable: false,
              position: toast.POSITION.TOP_RIGHT
            })

            $("[data-dismiss=modal]").trigger({type: "click"})
          })
        })
        .catch((error) => {
          this.setState({spinner: false}, function () {
            this.handleErrors(error)
          })
        })
    })
  }

  render() {
    const form = this.state.form

    return (
      <React.Fragment>
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <form method="post" onSubmit={this.handleSubmit}>
              <div className="modal-header">
                <h5 className="modal-title" id="help_form_modal_title">How can we help?</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.errors.length > 0 && this.renderErrors()}
                <div className="form-group">
                  <textarea className="form-control" id="help_message" value={form.message || ''}
                            name="message" rows="10" onChange={this.handleChange}/>
                </div>
              </div>
              <div className="modal-footer">
                {this.state.spinner && (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Uploading...</span>
                  </div>
                )}
                <input type="submit" className="btn btn-primary" value="Submit"/>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HelpRequest
