import React from 'react'
import FormBase from './FormBase'
import axios from 'axios'
import PropTypes from 'prop-types'

class Login extends FormBase {
  constructor(props) {
    super(props)

    if(this.props.flash_msg)
      this.state.errors = [this.props.flash_msg]

    this.required_fields = ['email', 'password']
    this.handleSubmitToServer = this.handleSubmitToServer.bind(this)
  }

  handleSubmitToServer(form) {
    axios.post('/api/user_sessions', form)
      .then((response) => {
        window.location.href = this.props.success_url
      })
      .catch((error) => {
        this.handleErrors(error)
      })
  }

  render() {
    const form = this.state.form

    return (
      <React.Fragment>
        <div id="layoutAuthentication">
          <div id="layoutAuthentication_content">
            <main>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-5">
                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                      <div className="card-header"><h3 className="text-center font-weight-light my-4">Candidate
                        Login</h3></div>
                      <div className="card-body">
                        {this.state.errors.length > 0 && this.renderErrors()}
                        <form method="post" onSubmit={this.handleSubmit}>
                          <div className="form-group">
                            <label className="small mb-1" htmlFor="email">Email</label>
                            <input className="form-control py-4" type="email" name="email"
                                   placeholder="Enter email address"
                                   value={form.email || ''} onChange={this.handleChange}/>
                          </div>
                          <div className="form-group">
                            <label className="small mb-1" htmlFor="password">Password</label>
                            <input className="form-control py-4" type="password" name="password"
                                   placeholder="Enter password"
                                   value={form.password || ''} onChange={this.handleChange}/>
                          </div>
                          <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                            <a className="small" href="/reset-password">Forgot Password?</a>
                            <input type="submit" className="btn btn-primary" value="Login"/>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  success_url: PropTypes.string.isRequired,
  flash_msg: PropTypes.string
}
export default Login
