import React from "react"
import PropTypes from "prop-types"
import FormBase from "./FormBase"
import axios from "axios"
import provider_types from "../../../data/provider_types.json"
import availability from "../../../data/availability.json"
import experience from "../../../data/experience.json"
import emrs from "../../../data/emrs.json"
import education from "../../../data/education.json"
import languages from "../../../data/languages.json"
import medical_compliance from "../../../data/medical_compliance.json"
import distance from "../../../data/distance.json"
import states from "../../../data/states.json"
import licensed_states from "../../../data/licensed_states.json"
import specialties from "../../../data/specialties.json"
import workplace from "../../../data/workplace.json"

// update 2 (used to force reload json above)

const PROVIDER_TYPES = Object.keys(provider_types).map(k => {return {name: provider_types[k].name, value: k}})
const STATE_LICENSE_REQUIRED = Object.keys(provider_types).filter(k => provider_types[k].requireStateLicense)
const TITLE_HASH = Object.keys(provider_types).reduce((title_hash, k) => {title_hash[k] = provider_types[k].name; return title_hash}, {})
const AVAILABILITY = Object.keys(availability).map(k => {return {name: availability[k], value: k}})
const EXPERIENCE = Object.keys(experience).map(k => {return {name: experience[k], value: k}})
const EMRS = Object.keys(emrs).map(k => {return {name: emrs[k], value: k}})
const EDUCATION = Object.keys(education).map(k => {return {name: education[k], value: k}})
const LANGUAGES = Object.keys(languages).map(k => {return {name: languages[k]['name'], value: k}})
const MEDICAL_COMPLIANCE = Object.keys(medical_compliance).map(k => {return {name: medical_compliance[k], value: k}})
const DISTANCE = Object.keys(distance).map(k => {return {name: distance[k], value: k}})
const STATES = Object.keys(states).map(k => {return {name: states[k], value: k}})
const LICENSED_STATES = Object.keys(licensed_states).map(k => {return {name: licensed_states[k], value: k}})
const SPECIALTIES = Object.keys(specialties).map(k => {return {name: specialties[k], value: k}})
const WORKPLACE = Object.keys(workplace).map(k => {return {name: workplace[k], value: k}})

class JobSeekerProfile extends FormBase {
    constructor(props) {
        super(props)

        this.myRef = React.createRef()

        let dateStr = ''
        if(this.props.profile.last_available_date != null) {
            dateStr = moment(this.props.profile.last_available_date).format('MM/DD/YYYY')
        } else {
            dateStr = moment().format('MM/DD/YYYY')
        }

        this.state = {
            ...this.state,
            photo_url: this.props.photo_url,
            form: {
                active: this.props.profile.active,
                confidential_search: this.props.profile.confidential_search || 0,
                title: this.props.profile.job_title || PROVIDER_TYPES[0].value,
                state_licenses: this.props.profile.state_licenses ? this.props.profile.state_licenses.split(',') : [],
                availability: this.props.profile.availability ? this.props.profile.availability.split(',') : [],
                last_available_date: dateStr,
                distance: this.props.profile.distance || DISTANCE[0].value,
                zipcode: this.props.profile.zipcode,
                workplace_preference: this.props.profile.workplace_preference ? this.props.profile.workplace_preference.split(',') : [],
                preferred_states: this.props.profile.preferred_states ? this.props.profile.preferred_states.split(',') : [],
                year_experience: this.props.profile.year_experience || EXPERIENCE[0].value,
                specialties: this.props.profile.specialties ? this.props.profile.specialties.split(',') : [],
                emrs: this.props.profile.emrs ? this.props.profile.emrs.split(',') : [],
                education: this.props.profile.education || EDUCATION[3].value,
                grad_year: this.props.profile.grad_year,
                languages: this.props.profile.languages ? this.props.profile.languages.split(',') : [],
                medical_compliance: this.props.profile.medical_compliance ? this.props.profile.medical_compliance.split(',') : [],
                xp_specialty_1: this.props.profile.xp_specialty_1 || '',
                xp_specialty_2: this.props.profile.xp_specialty_2 || '',
                xp_specialty_3: this.props.profile.xp_specialty_3 || '',
                xp_duration_1: this.props.profile.xp_duration_1 || '',
                xp_duration_2: this.props.profile.xp_duration_2 || '',
                xp_duration_3: this.props.profile.xp_duration_3 || ''
            },
            showStateLicenses: STATE_LICENSE_REQUIRED.includes(this.props.profile.job_title || PROVIDER_TYPES[0].value)
        }

        this.required_fields = []
        this.handleSubmitToServer = this.handleSubmitToServer.bind(this)
        this.handleMultiCheckChange = this.handleMultiCheckChange.bind(this)
        this.handleTitleChange = this.handleTitleChange.bind(this)
        this.handleRemoveCurrentPhoto = this.handleRemoveCurrentPhoto.bind(this)
    }

    handleRemoveCurrentPhoto(event) {
        event.preventDefault()

        axios.delete(`/api/profiles/${this.props.profile.id}/remove_photo`)
            .then((response) => {
                this.setState({
                    photo_url: null
                })
            })
            .catch((error) => {
                this.handleErrors(error)
                this.myRef.current.scrollIntoView()
            })
    }

    handleMultiCheckChange(event) {
        const target = event.target
        const value = target.value
        const checked = target.checked
        const name = target.name

        let form = {...this.state.form}
        if(checked && !form[name].includes(value))
            form[name].push(value)
        else if(!checked)
            form[name] = form[name].filter(function(v, _index, _arr) { return v != value })

        this.setState({
            form: form
        })
    }

    handleSubmitToServer(form) {
        const formData = new FormData()
        for (let key in form) {
            if (['last_available_date'].includes(key)) {
                const dateStr = moment(form[key], 'MM/DD/YYYY').format('YYYY-MM-DD')
                formData.append('profile[' + key + ']', dateStr)
            } else if (['active', 'confidential_search'].includes(key)) {
                // booleans are special
                formData.append('profile[' + key + ']', form[key])
            } else if (['photo', 'resume'].includes(key)) {
                // don't include file if it is blank
                const value = form[key]
                if(value) {
                    formData.append('profile[' + key + ']', value)
                }
            } else {
                const value = form[key] || ''
                formData.append('profile[' + key + ']', value)
            }
        }

        if (this.props.profile.id) {
            axios.patch('/api/profiles/' + this.props.profile.id, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then((response) => {
                    window.location.href = '/candidate/dashboard'
                })
                .catch((error) => {
                    this.handleErrors(error)
                    this.myRef.current.scrollIntoView()
                })
        } else {
            axios.post('/api/profiles', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then((response) => {
                    window.location.href = '/candidate/dashboard'
                })
                .catch((error) => {
                    this.handleErrors(error)
                    this.myRef.current.scrollIntoView()
                })
        }
    }

    handleTitleChange(event) {
        this.handleChange(event, () => {
            const newTitle = this.state.form.title
            const showStateLicenses = STATE_LICENSE_REQUIRED.includes(newTitle)

            if (showStateLicenses) {
                this.setState({
                    showStateLicenses: showStateLicenses
                })
            } else {
                let form = {...this.state.form}
                form.state_licenses = []
                this.setState({
                    showStateLicenses: showStateLicenses,
                    form: form
                })
            }
        })
    }

    render () {
        const form = this.state.form

        return (
            <React.Fragment>
                <div className="card mb-4">
                    <div className="card-header">Job Seeker Profile</div>
                    <div ref={this.myRef}></div>
                    <div className="card-body">
                        {this.state.errors.length > 0 && this.renderErrors()}
                        <form onSubmit={this.handleSubmit}>
                            <label className="mb-1">Are you actively seeking new employment opportunities?</label>
                            <div className="custom-control custom-radio">
                                <input className="custom-control-input" id='radioActiveYes'
                                       name="active" type="radio" value={1} checked={form.active == 1}
                                       onChange={this.handleChange}/>
                                <label className="custom-control-label" htmlFor='radioActiveYes'>Yes</label>
                            </div>
                            <div className="custom-control custom-radio">
                                <input className="custom-control-input" id='radioActiveNo'
                                       name="active" type="radio" value={0} checked={form.active == 0}
                                       onChange={this.handleChange}/>
                                <label className="custom-control-label" htmlFor='radioActiveNo'>No</label>
                            </div>
                            <div className={form.active == 1 ? '' : 'd-none'}>
                                <hr/>

                                <div className="form-row">
                                    <div className="form-group col-md-8">
                                        <label className="mb-1" htmlFor="filePhoto">Professional Profile Picture</label>
                                        <input className="form-control" id="filePhoto" type="file" name="photo"
                                               onChange={this.handleChange} accept={this.props.photo_types} />
                                        <small>70% of polled healthcare recruiters prefer recruitment profiles to
                                            include a profile picture. This helps prospective employers to put a face
                                            with the name.</small>
                                    </div>
                                    <div className="form-group col-md-1"></div>
                                    <div className="form-group col-md-3">
                                        {!this.state.photo_url && (
                                            <small>Current Photo: None</small>
                                        )}
                                        {this.state.photo_url && (
                                            <div>
                                                <small>Current Photo:</small>
                                                <img src={this.state.photo_url} className="img-fluid rounded-circle"/>
                                                <div className="text-center">
                                                    <a onClick={this.handleRemoveCurrentPhoto} className="btn btn-link">Remove</a>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="mb-1" htmlFor="selectAvailability">Availability</label>
                                    {AVAILABILITY.map((row) => {
                                        return (
                                            <div className="custom-control custom-checkbox" key={row.value}>
                                                <input className="custom-control-input" id={'checkbox_avail_' + row.value}
                                                       name="availability" type="checkbox" value={row.value}
                                                       checked={form.availability.includes(row.value)} onChange={this.handleMultiCheckChange}/>
                                                <label className="custom-control-label" htmlFor={'checkbox_avail_' + row.value}>{row.name}</label>
                                            </div>
                                        )
                                    })}
                                </div>

                                <hr className="mt-4 mb-4"/>

                                <div className="form-group">
                                    <label className="mb-1" htmlFor="fileResume">Résumé/CV</label>
                                    <input className="form-control" id="fileResume" type="file" name="resume"
                                           onChange={this.handleChange} accept={this.props.resume_types} />
                                    <small>Current Résumé/CV on File: {this.props.resume_filename || 'None'}</small>
                                </div>

                                <div className="form-group">
                                    <label className="mb-1" htmlFor="selectTitle">Title</label>
                                    <select className="form-control" id="selectTitle" name="title" value={form.title || ''}
                                            onChange={this.handleTitleChange}>
                                        {PROVIDER_TYPES.map((row) => {
                                            return <option key={row.value} value={row.value}>{row.name}</option>
                                        })}
                                    </select>
                                </div>

                                <div className={this.state.showStateLicenses ? 'form-group' : 'd-none'}>
                                    <label className="mb-1" htmlFor="selectStateLicenses">State Licenses for {TITLE_HASH[form.title]}</label>
                                    <div style={{height: 200}} className='multi-checkbox-select'>
                                        {LICENSED_STATES.map((row) => {
                                            return (
                                                <div className="custom-control custom-checkbox" key={row.value}>
                                                    <input className="custom-control-input" id={'checkbox_sl_' + row.value}
                                                           name="state_licenses" type="checkbox" value={row.value}
                                                           checked={form.state_licenses.includes(row.value)} onChange={this.handleMultiCheckChange}/>
                                                    <label className="custom-control-label" htmlFor={'checkbox_sl_' + row.value}>{row.name}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <small>Currently Selected: {form.state_licenses.map(k => licensed_states[k]).join(', ') || 'None'}</small>
                                </div>

                                <div className="form-group">
                                    <label className="mb-1" htmlFor="inputStartDate">Available to Start Date</label>
                                    <input className="form-control" id="inputStartDate" type="text" name="last_available_date"
                                           value={form.last_available_date || ''} onChange={this.handleChange}/>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label className="mb-1" htmlFor="inputZipcode">Zip Code</label>
                                        <input className="form-control" id="inputZipcode" type="text" name="zipcode"
                                               value={form.zipcode || ''} onChange={this.handleChange}/>
                                    </div>

                                    <div className="form-group col-md-8">
                                        <label className="mb-1" htmlFor="selectDistance">Commuting Distance (miles from zip code)</label>
                                        <select className="form-control" id="selectDistance" name="distance" value={form.distance || ''}
                                                onChange={this.handleChange}>
                                            {DISTANCE.map((row) => {
                                                return <option key={row.value} value={row.value}>{row.name}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="mb-1" htmlFor="selectPreferredStates">What states are you open to working in?</label>
                                    <div style={{height: 200}} className='multi-checkbox-select'>
                                        {STATES.map((row) => {
                                            return (
                                                <div className="custom-control custom-checkbox" key={row.value}>
                                                    <input className="custom-control-input" id={'checkbox_ps_' + row.value}
                                                           name="preferred_states" type="checkbox" value={row.value}
                                                           checked={form.preferred_states.includes(row.value)} onChange={this.handleMultiCheckChange}/>
                                                    <label className="custom-control-label" htmlFor={'checkbox_ps_' + row.value}>{row.name}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <small>Currently Selected: {form.preferred_states.map(k => states[k]).join(', ') || 'None'}</small>
                                </div>

                                <div className="form-group">
                                    <label className="mb-1" htmlFor="selectWorkplacePreference">Workplace Setting Preference</label>
                                    <div style={{height: 210}} className='multi-checkbox-select'>
                                        {WORKPLACE.map((row) => {
                                            return (
                                                <div className="custom-control custom-checkbox" key={row.value}>
                                                    <input className="custom-control-input" id={'checkbox_wp_' + row.value}
                                                           name="workplace_preference" type="checkbox" value={row.value}
                                                           checked={form.workplace_preference.includes(row.value)} onChange={this.handleMultiCheckChange}/>
                                                    <label className="custom-control-label" htmlFor={'checkbox_wp_' + row.value}>{row.name}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <small>Currently Selected: {form.workplace_preference.map(k => workplace[k]).join(', ') || 'None'}</small>
                                </div>

                                <hr className="mt-4 mb-4"/>

                                <div className="form-group">
                                    <label className="mb-1" htmlFor="selectExperience">Experience</label>
                                    <select className="form-control" id="selectExperience" name="year_experience" value={form.year_experience || ''}
                                            onChange={this.handleChange}>
                                        {EXPERIENCE.map((row) => {
                                            return <option key={row.value} value={row.value}>{row.name}</option>
                                        })}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-12">
                                            <label className="mb-1" htmlFor="">Choose Up To Three Specialties You Have Experience In</label>
                                        </div>
                                        <div className="col-6 mb-1">
                                            <select className="form-control" id="selectExperience" name="xp_specialty_1" value={form.xp_specialty_1 || ''}
                                                    onChange={this.handleChange}>
                                                <option value=''>Select Specialty</option>
                                                {SPECIALTIES.map((row) => {
                                                    return <option key={row.value} value={row.value}>{row.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-6 mb-1">
                                            <select className="form-control" id="selectExperience" name="xp_duration_1" value={form.xp_duration_1 || ''}
                                                    onChange={this.handleChange}>
                                                <option value=''>Select Duration</option>
                                                {EXPERIENCE.map((row) => {
                                                    return <option key={row.value} value={row.value}>{row.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-6 mb-1">
                                            <select className="form-control" id="selectExperience" name="xp_specialty_2" value={form.xp_specialty_2 || ''}
                                                    onChange={this.handleChange}>
                                                <option value=''>Select Specialty</option>
                                                {SPECIALTIES.map((row) => {
                                                    return <option key={row.value} value={row.value}>{row.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-6 mb-1">
                                            <select className="form-control" id="selectExperience" name="xp_duration_2" value={form.xp_duration_2 || ''}
                                                    onChange={this.handleChange}>
                                                <option value=''>Select Duration</option>
                                                {EXPERIENCE.map((row) => {
                                                    return <option key={row.value} value={row.value}>{row.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-6 mb-1">
                                            <select className="form-control" id="selectExperience" name="xp_specialty_3" value={form.xp_specialty_3 || ''}
                                                    onChange={this.handleChange}>
                                                <option value=''>Select Specialty</option>
                                                {SPECIALTIES.map((row) => {
                                                    return <option key={row.value} value={row.value}>{row.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-6 mb-1">
                                            <select className="form-control" id="selectExperience" name="xp_duration_3" value={form.xp_duration_3 || ''}
                                                    onChange={this.handleChange}>
                                                <option value=''>Select Duration</option>
                                                {EXPERIENCE.map((row) => {
                                                    return <option key={row.value} value={row.value}>{row.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="mb-1" htmlFor="selectSpecialties">Specialty Preferences</label>
                                    <div style={{height: 200}} className='multi-checkbox-select'>
                                        {SPECIALTIES.map((row) => {
                                            return (
                                                <div className="custom-control custom-checkbox" key={row.value}>
                                                    <input className="custom-control-input" id={'checkbox_spec_' + row.value}
                                                           name="specialties" type="checkbox" value={row.value}
                                                           checked={form.specialties.includes(row.value)} onChange={this.handleMultiCheckChange}/>
                                                    <label className="custom-control-label" htmlFor={'checkbox_spec_' + row.value}>{row.name}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <small>Currently Selected: {form.specialties.map(k => specialties[k]).join(', ') || 'None'}</small>
                                </div>

                                <div className="form-group">
                                    <label className="mb-1" htmlFor="selectEmrs">EMRs (select all that apply)</label>
                                    <div style={{height: 200}} className='multi-checkbox-select'>
                                        {EMRS.map((row) => {
                                            return (
                                                <div className="custom-control custom-checkbox" key={row.value}>
                                                    <input className="custom-control-input" id={'checkbox_emrs_' + row.value}
                                                           name="emrs" type="checkbox" value={row.value}
                                                           checked={form.emrs.includes(row.value)} onChange={this.handleMultiCheckChange}/>
                                                    <label className="custom-control-label" htmlFor={'checkbox_emrs_' + row.value}>{row.name}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <small>Currently Selected: {form.emrs.map(k => emrs[k]).join(', ') || 'None'}</small>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-8">
                                        <label className="mb-1" htmlFor="selectEducation">Education (select highest level)</label>
                                        <select className="form-control" id="selectEducation" name="education" value={form.education || ''}
                                                onChange={this.handleChange}>
                                            {EDUCATION.map((row) => {
                                                return <option key={row.value} value={row.value}>{row.name}</option>
                                            })}
                                        </select>
                                    </div>

                                    <div className="form-group col-md-4">
                                        <label className="mb-1" htmlFor="inputGradYear">Graduation Year</label>
                                        <input className="form-control" id="inputGradYear" type="text" name="grad_year"
                                               onChange={this.handleChange} value={form.grad_year || ''}/>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="mb-1" htmlFor="selectLanguages">Languages (select all that apply)</label>
                                    <div style={{height: 200}} className='multi-checkbox-select'>
                                        {LANGUAGES.map((row) => {
                                            return (
                                                <div className="custom-control custom-checkbox" key={row.value}>
                                                    <input className="custom-control-input" id={'checkbox_lang_' + row.value}
                                                           name="languages" type="checkbox" value={row.value}
                                                           checked={form.languages.includes(row.value)} onChange={this.handleMultiCheckChange}/>
                                                    <label className="custom-control-label" htmlFor={'checkbox_lang_' + row.value}>{row.name}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <small>Currently Selected: {form.languages.map(k => languages[k].name).join(', ') || 'None'}</small>
                                </div>

                                <div className="form-group">
                                    <label className="mb-1" htmlFor="selectLanguages">Medical Compliance – Please check all that are current</label>

                                    {MEDICAL_COMPLIANCE.map((row) => {
                                        return (
                                            <div className="custom-control custom-checkbox" key={row.value}>
                                                <input className="custom-control-input" id={'checkbox_mc_' + row.value}
                                                       name="medical_compliance" type="checkbox" value={row.value}
                                                       checked={form.medical_compliance.includes(row.value)} onChange={this.handleMultiCheckChange}/>
                                                <label className="custom-control-label" htmlFor={'checkbox_mc_' + row.value}>{row.name}</label>
                                            </div>
                                        )
                                    })}
                                </div>

                                <hr className="mt-4 mb-4"/>

                                <label className="mb-1">Confidentially searching?</label>
                                <div className="custom-control custom-radio">
                                    <input className="custom-control-input" id='radioConfSearchYes'
                                           name="confidential_search" type="radio" value={1} checked={form.confidential_search == 1}
                                           onChange={this.handleChange}/>
                                    <label className="custom-control-label" htmlFor='radioConfSearchYes'>Yes</label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input className="custom-control-input" id='radioConfSearchNo'
                                           name="confidential_search" type="radio" value={0} checked={form.confidential_search == 0}
                                           onChange={this.handleChange}/>
                                    <label className="custom-control-label" htmlFor='radioConfSearchNo'>No</label>
                                </div>
                            </div>

                            <hr className="mt-4 mb-4"/>

                            <input type="submit" className="btn btn-primary" value="Save Changes"/>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

JobSeekerProfile.propTypes = {
    profile: PropTypes.object.isRequired,
    resume_filename: PropTypes.string,
    photo_url: PropTypes.string,
    resume_types: PropTypes.string.isRequired,
    photo_types: PropTypes.string.isRequired
}
export default JobSeekerProfile
