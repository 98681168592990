import React from "react"
import PropTypes from "prop-types"
import FormBase from "./FormBase"
import axios from "axios"

class PasswordRecovery extends FormBase {
  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      showInfo: false,
      form: {
        email: this.props.email
      }
    }

    this.required_fields = ['email']
    this.handleSubmitToServer = this.handleSubmitToServer.bind(this)
  }

  handleSubmitToServer(form) {
    axios.post('/api/password_resets', form)
      .then((response) => {
        this.setState({
          showInfo: true,
          errors: []
        })
      })
      .catch((error) => {
        this.handleErrors(error)
      })
  }

  render () {
    const form = this.state.form

    return (
      <React.Fragment>
        <div id="layoutAuthentication">
          <div id="layoutAuthentication_content">
            <main>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-5">
                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                      <div className="card-header"><h3 className="text-center font-weight-light my-4">Reset
                        Password</h3></div>
                      <div className="card-body">
                        {this.state.showInfo && <p>Please check your email. We have sent you a link to reset your password.</p>}
                        {this.state.errors.length > 0 && this.renderErrors()}
                        {!this.state.showInfo &&
                        <div>
                          <div className="small mb-3 text-muted">Enter your email address and we will send you a link to
                            reset your password.
                          </div>
                          <form method="post" onSubmit={this.handleSubmit}>
                            <div className="form-group">
                              <label className="small mb-1" htmlFor="email">Email</label>
                              <input className="form-control py-4" type="email" name="email"
                                     placeholder="Enter email address"
                                     value={form.email || ''} onChange={this.handleChange}/>
                            </div>
                            <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                              <input type="submit" className="btn btn-primary" value="Reset Password"/>
                            </div>
                          </form>
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

PasswordRecovery.propTypes = {
  email: PropTypes.string
}
export default PasswordRecovery
