import React from "react"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"

const ALERT_FLASH_MAP = {
  'error': toast.TYPE.ERROR,
  'success': toast.TYPE.SUCCESS,
  'warn': toast.TYPE.WARNING,
  'info': toast.TYPE.INFO,
  'notice': toast.TYPE.INFO
};

class Alerts extends React.Component {
  componentDidMount() {
    this.props.alerts.forEach(alert => {
      toast(alert[1], {
        type: ALERT_FLASH_MAP[alert[0]],
        closeButton: true,
        autoClose: 5000,
        hideProgressBar: true,
        draggable: false,
        position: toast.POSITION.TOP_RIGHT
      });
    });
  }

  render () {
    return (
      <React.Fragment>
        <ToastContainer />
      </React.Fragment>
    )
  }

  renderAlert(type, alert, idx) {
    return (
      <div className="toast" role="alert" aria-live="assertive" aria-atomic="true" key={idx}>
        <div className="toast-header">
          <strong className="mr-auto">Bootstrap</strong>
          <small className="text-muted">just now</small>
          <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="toast-body">
          See? Just like this.
        </div>
      </div>
    )
  }
}

Alerts.propTypes = {
  alerts: PropTypes.array
}
export default Alerts
