import React from "react"
import PropTypes from "prop-types"
import FormBase from "./FormBase"
import axios from "axios"

class Profile extends FormBase {
  constructor(props) {
    super(props)

    this.state = {
      ...this.state,
      form: {
        first_name: this.props.user.first_name,
        last_name: this.props.user.last_name,
        email: this.props.user.email,
        phone: this.props.user.phone
      }
    }

    this.required_fields = ['first_name', 'last_name', 'email', 'phone']
    this.handleSubmitToServer = this.handleSubmitToServer.bind(this)
  }

  handleSubmitToServer(form) {
    axios.patch('/api/users/' + this.props.user.ident, {user: form})
      .then((response) => {
        window.location.href = '/candidate/profile'
      })
      .catch((error) => {
        this.handleErrors(error)
      })
  }

  render() {
    const form = this.state.form

    return (
      <React.Fragment>
        {this.state.errors.length > 0 && this.renderErrors()}
        <form onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="small mb-1" htmlFor="inputFirstName">First name</label>
              <input className="form-control" id="inputFirstName" type="text" placeholder="Enter first name"
                     name="first_name" value={form.first_name || ''} onChange={this.handleChange}/>
            </div>
            <div className="form-group col-md-6">
              <label className="small mb-1" htmlFor="inputLastName">Last name</label>
              <input className="form-control" id="inputLastName" type="text" placeholder="Enter last name"
                     name="last_name" value={form.last_name || ''} onChange={this.handleChange}/>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="small mb-1" htmlFor="inputEmailAddress">Email address</label>
              <input className="form-control" id="inputEmailAddress" type="email" placeholder="Enter email address"
                     name="email" value={form.email || ''} onChange={this.handleChange} disabled={true}/>
            </div>
            <div className="form-group col-md-6">
              <label className="small mb-1" htmlFor="inputPhone">Phone number</label>
              <input className="form-control" id="inputPhone" type="tel" placeholder="Enter phone number"
                     name="phone" value={form.phone || ''} onChange={this.handleChange}/>
            </div>
          </div>
          <input type="submit" className="btn btn-primary" value="Save Changes"/>
        </form>
      </React.Fragment>
    );
  }
}

Profile.propTypes = {
  user: PropTypes.object.isRequired
}
export default Profile