import React from "react"
import PropTypes from "prop-types"
import FormBase from "./FormBase"
import {parseTime, generateTimes, isEmpty, parseLunch} from "../utils"
import moment from "moment"
import axios from "axios"

const OT_CUTOFF = 40

const TIMES = generateTimes(15)

const LUNCH_DURATIONS = [
    '0 min',
    '15 min',
    '30 min',
    '45 min',
    '60 min',
    '75 min',
    '90 min'
]

const WEEK_DAYS = [
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri'
]
const DAYS = ['Sun'].concat(WEEK_DAYS, ['Sat'])
const HOURS = Array.from({length: 24}, (_, i) => i);

class DigitalTimesheet extends FormBase {
    constructor(props) {
        super(props)

        let defaultForm = this.resetForm({
            assignment_id: this.props.assignment_id,
            week_of_date: this.props.fridays[0],
            no_hours: false
        })
        defaultForm = this.setTableHeaders(defaultForm)
        defaultForm = this.setDefaultTimes(defaultForm)
        const validations = this.calculateHours(defaultForm)

        this.state = {
            ...this.state,
            form: defaultForm,
            validations: validations,
            errors: [],
            spinner: false
        }

        this.required_fields = []
        this.handleChangeWithCalc = this.handleChangeWithCalc.bind(this)
        this.handleChangeWithReset = this.handleChangeWithReset.bind(this)
        this.handleSubmitToServer = this.handleSubmitToServer.bind(this)
    }

    resetForm(form) {
        DAYS.forEach(day => {
            form['data_loc_' + day] = this.props.locations[0]
            form['data_st_' + day] = ''
            form['data_et_' + day] = ''
            form['data_ln_' + day] = LUNCH_DURATIONS[0]
            form['data_pto_' + day] = HOURS[0]
        })

        return form
    }


    setDefaultTimes(form) {
        WEEK_DAYS.forEach(day => {
            form['data_st_' + day] = '09:00 AM'
            form['data_et_' + day] = '05:00 PM'
        })

        return form
    }

    setTableHeaders(form) {
        DAYS.forEach((day, index) => {
            let dd = moment(form.week_of_date, 'MM/DD/YYYY').add(-5 + index, 'days')
            let holiday = this.isHoliday(dd)
            let hdr = day + ' ' + dd.format('M/D') + (holiday ? '*' : '')

            form['data_hdr_' + day] = hdr
        })

        return form
    }

    calculateHours(form) {
        let validations = {}
        let reg_total = 0
        let ot_total = 0
        let holiday_total = 0
        let pto_total = 0

        DAYS.forEach((day, index) => {
            let start = parseTime(form['data_st_' + day])
            let end = parseTime(form['data_et_' + day])
            let lunch = parseLunch(form['data_ln_' + day])
            let pto = parseInt(form['data_pto_' + day])
            let diff = end - start
            let hours = diff / 60
            let reg_hours, ot_hours = 0

            let dd = moment(form.week_of_date, 'MM/DD/YYYY').add(-5 + index, 'days')

            pto_total += pto

            if (this.props.subtract_lunch) {
                hours -= lunch
            }

            if (start == null || end == null) {
                reg_hours = null
                ot_hours = null
            } else if (this.isHoliday(dd)) {
                reg_hours = 0  // counts toward regular hours worked
                ot_hours = hours
                holiday_total += ot_hours
            } else if (reg_total > OT_CUTOFF) {
                reg_hours = 0
                ot_hours = hours
                ot_total += ot_hours
            } else if (holiday_total + reg_total + hours <= OT_CUTOFF) {
                reg_hours = hours
                ot_hours = 0
                reg_total += reg_hours
            } else {
                let diff_hours = holiday_total + reg_total + hours - OT_CUTOFF
                reg_hours = hours - diff_hours
                ot_hours = diff_hours
                reg_total += reg_hours
                ot_total += ot_hours
            }

            form['data_reg_' + day] = reg_hours
            form['data_ot_' + day] = ot_hours
            form['data_th_' + day] = reg_hours + ot_hours

            if (reg_hours < 0 || ot_hours < 0) validations['data_reg_' + day] = 'Invalid start/end times.'
            if ((reg_hours + ot_hours) < 0) validations['data_th_' + day] = 'Invalid start/end times.'
        })

        form['data_reg_total'] = reg_total
        form['data_ot_total'] = ot_total + holiday_total
        form['data_total_hours'] = reg_total + ot_total + holiday_total
        form['data_total_pto_hours'] = pto_total

        return validations
    }

    handleChangeWithReset(event) {
        this.handleChange(event, () => {
            const form = this.setTableHeaders(this.state.form)

            let resetForm = this.resetForm(form)
            if (!form.no_hours) resetForm = this.setDefaultTimes(resetForm)
            const validations = this.calculateHours(resetForm)

            this.setState({
                form: resetForm,
                validations: validations,
                errors: [],
                spinner: false
            })
        })
    }

    handleChangeWithCalc(event) {
        this.handleChange(event, () => {
            const form = this.setTableHeaders(this.state.form)

            const validations = this.calculateHours(form)
            this.setState({
                form: form,
                validations: validations
            })
        })
    }

    runValidations(form) {
        const validations = this.calculateHours(form)
        DAYS.forEach(day => {
            if ((form['data_reg_' + day] || form['data_ot_' + day])
                && isEmpty(form['data_loc_' + day])) validations['data_loc_' + day] = 'Location missing.'
            if (form['data_st_' + day] == '' && form['data_et_' + day] != '')
                validations['data_st_' + day] = validations['data_et_' + day] = 'Enter start and end time.'
            if (form['data_st_' + day] != '' && form['data_et_' + day] == '')
                validations['data_st_' + day] = validations['data_et_' + day] = 'Enter start and end time.'
            if (form['data_st_' + day] != '' && form['data_et_' + day] != '' &&
                form['data_st_' + day] == form['data_et_' + day])
                validations['data_st_' + day] = validations['data_et_' + day] = 'Start and time can\'t be the same.'
        })
        return validations
    }

    handleSubmitToServer(form) {
        // validate
        const validations = this.runValidations(form)
        if (Object.keys(validations).length !== 0) {
            this.setState({
                spinner: false,
                validations: validations
            })
            return
        }

        let data = {}
        Object.keys(form).forEach(key => {
            if (key.startsWith('data_')) {
                data[key.slice(5)] = form[key]
            }
        })

        let dataForm = {
            assignment_id: form.assignment_id,
            week_of_date: form.week_of_date,
            no_hours: form.no_hours,
            is_digital: true,
            data: data
        }

        this.setState({spinner: true}, function () {
            axios.post('/api/timesheets', dataForm)
                .then((response) => {
                    this.setState({spinner: false})
                    window.location = this.props.success_url
                })
                .catch((error) => {
                    this.setState({spinner: false}, function () {
                        this.handleErrors(error)
                    })
                })
        })
    }

    isHoliday(dd) {
        return this.props.holidays.indexOf(dd.format('MM/DD/YYYY')) >= 0
    }

    render() {
        const form = this.state.form
        const validations = this.state.validations

        return (
            <React.Fragment>
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className="modal-content">
                        <form method="post" onSubmit={this.handleSubmit}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="timesheet_{this.props.assignment_id}_title">Weekly
                                    Timesheet
                                    for {this.props.title}</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                {this.state.errors.length > 0 && this.renderErrors()}

                                <div className="form-group">
                                    <label htmlFor="timesheet_week_of_date">Week Ending Date</label>
                                    <select className="form-control" name="week_of_date"
                                            value={form.week_of_date} onChange={this.handleChangeWithCalc}>
                                        {this.props.fridays.map((friday) =>
                                            <option value={friday} key={friday}>{friday}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="form-group row px-3">
                                    <div className="form-check col">
                                        <input className="form-check-input" id="timesheet_no_hours" type="checkbox"
                                               name="no_hours" checked={form.no_hours} value={true}
                                               onChange={this.handleChangeWithReset}/>
                                        <label className="form-check-label" htmlFor="timesheet_no_hours">I Worked No
                                            Hours During This Week</label>
                                    </div>
                                </div>

                                <div className="form-group mt-5">
                                    <div className="table-responsive">
                                        <table className="table table-timesheet">
                                            <thead>
                                            <tr>
                                                <th></th>
                                                {DAYS.map(row => {
                                                    return <th key={row}>{form['data_hdr_' + row]}</th>
                                                })}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.selectRow('Location', 'data_loc_', form, validations, this.props.locations)}

                                            {this.selectRow('Start Time', 'data_st_', form, validations, TIMES)}
                                            {this.selectRow('End Time', 'data_et_', form, validations, TIMES)}

                                            {this.props.subtract_lunch && this.selectRow('Lunch', 'data_ln_', form, validations, LUNCH_DURATIONS)}
                                            {this.selectRow('PTO', 'data_pto_', form, validations, HOURS)}

                                            {this.inputRow('Total Hours', 'data_th_', form, validations, true)}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-lg-9">
                                            <div className="small mb-3">
                                                Dates with * are holidays and are marked as overtime.<br/>
                                                Please scroll left-right to see all 7 days.<br/>
                                                PTO does not contribute to total hours.
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-3">
                                            <table className="table table-borderless table-sm">
                                                <tbody>
                                                <tr className="small">
                                                    <td className="text-right">Total Regular Hours:</td>
                                                    <td className="text-right total-reg-hours">{form.data_reg_total.toFixed(2)}</td>
                                                </tr>
                                                <tr className="small">
                                                    <td className="text-right">Total OT Hours:</td>
                                                    <td className="text-right total-ot-hours">{form.data_ot_total.toFixed(2)}</td>
                                                </tr>
                                                <tr className="small font-weight-700">
                                                    <td className="text-right">Total Hours:</td>
                                                    <td className="text-right total-hours">{form.data_total_hours.toFixed(2)}</td>
                                                </tr>
                                                <tr className="small">
                                                    <td className="text-right">Total PTO Hours:</td>
                                                    <td className="text-right total-pto-hours">{form['data_total_pto_hours'].toFixed(2)}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                {this.state.spinner && (
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Uploading...</span>
                                    </div>
                                )}
                                <input type="submit" className="btn btn-primary" value="Submit for Approval"
                                       disabled={this.state.spinner}/>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    inputRow(row_header, key_prefix, form, validations, readOnly = false, defaultValue = '') {
        return (
            <tr>
                <th nowrap="nowrap">{row_header}</th>
                {DAYS.map((day) => {
                    return (
                        <td key={day}>
                            <input
                                className={'form-control form-control-sm ' + (validations[key_prefix + day] ? 'is-invalid' : '')}
                                name={key_prefix + day} type="text" value={form[key_prefix + day] || defaultValue}
                                onChange={this.handleChangeWithCalc} readOnly={readOnly}
                                title={validations[key_prefix + day]} disabled={form.no_hours}
                                style={{minWidth: '100px'}}/>
                        </td>
                    )
                })}
            </tr>
        )
    }

    // checkboxRow(row_header, key_prefix, form, defaultValue = false) {
    //     return (
    //         <tr>
    //             <th nowrap="nowrap">{row_header}</th>
    //             {DAYS.map((day) => {
    //                 return (
    //                     <td key={day}>
    //                         <div className={'custom-control custom-checkbox'} style={{padding: 0}}>
    //                             <input name={key_prefix + day} checked={form[key_prefix + day]} value={true}
    //                                    type="checkbox" onChange={this.handleChangeWithCalc} disabled={form.no_hours}/>
    //                         </div>
    //                     </td>
    //                 )
    //             })}
    //         </tr>
    //     )
    // }

    selectRow(row_header, key_prefix, form, validations, selectOptions) {
        return (
            <tr>
                <th nowrap="nowrap">{row_header}</th>
                {DAYS.map((day) => {
                    return (
                        <td key={day}>
                            <select
                                className={'form-control form-control-sm ' + (validations[key_prefix + day] ? 'is-invalid' : '')}
                                name={key_prefix + day}
                                value={form[key_prefix + day]} onChange={this.handleChangeWithCalc}
                                disabled={form.no_hours} style={{minWidth: '100px'}}>
                                {selectOptions.map((time) => {
                                    return <option key={time} value={time}>{time}</option>
                                })}
                            </select>
                        </td>
                    )
                })}
            </tr>
        )
    }
}

DigitalTimesheet.propTypes = {
    assignment_id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    locations: PropTypes.array.isRequired,
    success_url: PropTypes.string.isRequired,
    fridays: PropTypes.array.isRequired,
    holidays: PropTypes.array.isRequired,
    subtract_lunch: PropTypes.bool.isRequired
}
export default DigitalTimesheet
